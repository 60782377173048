<template lang="pug">
LcapModal.confirm-modal(v-model="_value", hideFooter)
	.confirm-modal-content
		h2.confirm-msg {{ message }}
		.btns
			button.btn-cancel(@click="_value = false") No, go back
			button.confirm-btn.btn.btn-primary(@click="confirm") Confirm
</template>

<script>
import LcapModal from "@/components/LcapModal.vue";

export default {
	name: "ConfirmModal",
	components: {
		LcapModal,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		message: {
			type: String,
			default: "",
		},
		variant: {
			type: String,
			default: "",
		},
	},
	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(v) {
				this.$emit("input", v);
			},
		},
	},
	methods: {
		confirm() {
			this.$emit("confirm");
			this._value = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.confirm-modal {
	.confirm-modal-content {
		padding: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		.confirm-msg {
			max-width: 500px;
			text-align: center;
		}
		.btns {
			display: flex;
			gap: 36px;
		}
	}
}
</style>
