<template>
	<button class="open-close-btn" @click="$emit('click', $event)">
		<div
			id="nav-icon"
			class="open-close-icon"
			:class="{ open }"
		>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</button>
</template>

<script>
export default {
	props: {
		open: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>

// https://codepen.io/designcouch/pen/Atyop

$nav-icon-spacing: 15px;

.open-close-icon {
	width: 42px;
	height: 30px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
	color: inherit;
}

.open-close-icon span {
	display: block;
	position: absolute;
	height: 5px;
	width: 100%;
	background: currentColor;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

.open-close-icon span:nth-child(1) {
	top: 0px;
}

.open-close-icon span:nth-child(2),
.open-close-icon span:nth-child(3) {
	top: 15px;
}

.open-close-icon span:nth-child(4) {
	top: 30px;
}

.open-close-icon.open span:nth-child(1) {
	top: 15px;
	width: 0%;
	left: 50%;
}

.open-close-icon.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.open-close-icon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.open-close-icon.open span:nth-child(4) {
	top: 15px;
	width: 0%;
	left: 50%;
}
</style>