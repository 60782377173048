<template lang="pug">
MainView
</template>

<script>
import MainView from "@/components/MainView.vue";

export default {
	name: "Home",
	components: {
		MainView,
	},
};
</script>
