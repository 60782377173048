export function localDistrictDataToPriorities(data) {
	return Object.entries(data.priorities).map(([key, value]) => {
		const metrics = data.metrics[value.children]
			? Object.entries(data.metrics[value.children]).map(([key, val]) => ({
					key,
					groupKey: value.children,
					name: val.name,
					desc: val.desc,
					data: val.data,
			  })) // eslint-disable-line
			: [];
		return {
			key,
			name: value.name,
			desc: value.desc,
			metricsKey: value.children,
			children: value.children,
			metrics,
		};
	});
}
