<template lang="pug">
.select-input
	label.label {{ label }}
	.dropdown-menu(
		:class="{ opened: isOpen }",
		:tabindex="0",
		@click="isOpen = true",
		@keydown.enter="isOpen = true",
		@blur="isOpen = false",
		@mouseleave="isOpen = false"
	)
		p.curr-value {{ currentLabel }}
		.options-wrapper
			.options
				.option(
					v-for="option in options",
					:class="{ active: value == option.value }",
					@click.stop="onSelect(option)"
				)
					p {{ option.label }}
</template>

<script>
export default {
	name: "SelectInput",
	props: {
		value: [String, Number],
		label: String,
		options: Array,
	},
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		currentLabel() {
			const op = this.options.find((op) => op.value === this.value);
			return op ? op.label : "";
		},
	},
	mounted() {
		// if (!this.value && this.options) this.$emit("input", this.options[0].value);
	},
	methods: {
		onSelect(option) {
			this.$emit("input", option.value);
			this.isOpen = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

$box-shadow: 0px 5px 16px rgba(42, 49, 58, 0.32);

.select-input {
	display: flex;
	flex-direction: column;
	.label {
		font-size: 12px;
		color: rgb(79, 79, 79);
		margin-left: 13px;
	}
}

.dropdown-menu {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: 10px 10px;
	cursor: pointer;
	color: #646971;
	border: $border-lightgray;
	background-color: white;
	padding-right: 25px;
	transition: all 0.2s ease;

	&:active,
	&:focus {
		border-color: $color-lightblue;
	}

	&.hidden {
		display: none;
	}

	.curr-value {
		color: $color-lightblue;
		// text-transform: uppercase;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 14px;
		font-weight: 600;
	}

	&::after {
		border-color: $color-lightblue;
	}

	&.opened {
		&::after {
			transform: translate(-100%, -34%) rotate(225deg);
		}

		.arrow {
			opacity: 1;
			pointer-events: all;
		}

		.options-wrapper {
			opacity: 1;
			pointer-events: all;
		}
	}

	&:after {
		position: absolute;
		width: 4px;
		height: 4px;
		top: 50%;
		right: 0;
		border-right: #4a4a4a solid 2px;
		border-bottom: #4a4a4a solid 2px;
		transform: translate(-100%, -66%) rotate(45deg);
		transition: all 0.2s ease;
		content: " ";
	}

	.options-wrapper {
		opacity: 0;
		position: absolute;
		top: calc(100%);
		padding-top: 10px;
		transition: all 0.2s ease;
		pointer-events: none;
		left: 0;
		min-width: 100%;
		white-space: nowrap;
		z-index: 1;
		overflow: visible;
	}

	.options {
		box-shadow: $box-shadow;
		border-radius: 4px;
		padding: 5px;
		background-color: white;
		max-height: 500px;
		overflow: auto;

		&.left {
			left: 0%;
			transform: translateX(0%);
		}

		.option {
			display: flex;
			padding: 5px 10px 5px 35px;
			position: relative;

			&::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 10px;
				height: 12px;
				width: 12px;
				border: 2px solid $color-lightblue;
				// background-color: $color-lightblue;
				border-radius: 999px;
				transform: translateY(-50%);
			}

			&.active {
				&::before {
					content: " ";
					height: 8px;
					width:  8px;
					position: absolute;
					top: 50%;
					left: 14px;
					transform: translateY(-50%);
					background-color: $color-lightblue;
					border-radius: 999px;
					z-index: 1;
				}
			}

			// Checkbox implementation.
			// &::after {
			// 	content: " ";
			// 	position: absolute;
			// 	top: 50%;
			// 	left: 10px;
			// 	height: 12px;
			// 	width: 12px;
			// 	border: 2px solid $color-lightblue;
			// 	transform: translateY(-50%);
			// 	border-radius: 3px;
			// }

			// &.active {
			// 	&::after {
			// 		background-color: $color-lightblue;
			// 	}

			// 	&::before {
			// 		content: " ";
			// 		height: 4px;
			// 		width: 8px;
			// 		position: absolute;
			// 		top: 18px;
			// 		left: 12px;
			// 		border-left: 3px solid white;
			// 		border-bottom: 3px solid white;
			// 		transform: translateY(calc(-50% - 3px)) rotate(-45deg);
			// 		z-index: 10;
			// 	}
			// }
		}
	}
}
</style>
