import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Index",
		component: Index,
	},
	{
		path: "/login",
		name: "Login",
		component: () =>
			import(/* webpackChunkName: "home" */ "../views/Login.vue"),
	},
	{
		path: "/home",
		name: "Home",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
	},
	{
		path: "/edit",
		name: "Edit",
		component: () => import(/* webpackChunkName: "edit" */ "../views/Edit.vue"),
	},
	{
		path: "/upload",
		name: "Upload",
		component: () =>
			import(/* webpackChunkName: "edit" */ "../views/Upload.vue"),
	},
	{
		path: "/manage",
		name: "Manage",
		component: () =>
			import(/* webpackChunkName: "edit" */ "../views/Manage.vue"),
	},
	{
		path: "/test",
		name: "Test",
		component: () => import(/* webpackChunkName: "edit" */ "../views/Test.vue"),
	},
	{
		path: "*",
		redirect: "/",
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
