<template lang="pug">
nav#side-nav(:class="stateClasses")
	.backdrop(v-if="isOpen", @click="$emit('input', 'closed')")
	AnimatedHamburger.mobile-hamburger(
		:open="isOpen",
		@click="onMobileHamburgerClick"
	)
	button.back-btn(@click="onBack")
		ChevronLeftIcon.chev-left
		| Back
	.main-nav
		.top-container
			img.lcap-logo(
				src="../assets/img/lcap-logo-white.svg",
				alt="LCAP Logo",
				@click="$emit('logoClick')"
			)
			p.nav-title MUHSD Local Priorities
		template(v-if="priorities")
			.nav-item(
				v-for="priority in priorities",
				:class="{ active: activePriorityKey === priority.key }",
				@click="selectPriority(priority)"
			)
				input(
					v-if="isEditing && currEditKey === priority.key",
					ref="priorityInput",
					v-model="editValue",
					@blur="currEditKey = ''",
					@keyup.enter="saveEdit('priority', priority)"
				)
				p(v-else) {{ priority.name }}
				ChevronRightIcon.chevron-right(v-if="!isEditing")
				button(v-else, @click.prevent.stop="showEditInput('priority', priority)")
					EditIcon
		.logo-and-info
			img.muhsd-logo(src="../assets/img/muhsd-logo.png", alt="MUHSD Logo")
			button.info-btn.btn(@click="$emit('lcapInfo')") What is LCAP?
	.sub-nav
		.menu-icon-wrapper(@click="onSubNavMenuClick")
			MenuIcon.menu-icon
		.top-container
			p.pretitle {{ activePriority && activePriority.name }}
			p.nav-title Select a Metric
		ul.metrics(v-if="activePriority")
			li.metric(
				v-for="metric in sortedMetrics",
				:class="{ active: metric.key === currentMetricKey }",
				@click="selectMetric(metric)"
			)
				input(
					v-if="isEditing && currEditKey === metric.key",
					ref="metricInput",
					v-model="editValue",
					@blur="currEditKey = ''",
					@click.stop="",
					@keyup.enter="saveEdit('metric', metric)"
				)
				p(v-else) {{ metric.name }}
				button(
					v-if="isEditing",
					@click.prevent.stop="showEditInput('metric', metric)"
				)
					EditIcon
</template>

<script>
import AnimatedHamburger from "@/components/AnimatedHamburger.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import MenuIcon from "@/components/icons/MenuIcon.vue";

export default {
	name: "SideNav",
	components: {
		AnimatedHamburger,
		ChevronLeftIcon,
		ChevronRightIcon,
		EditIcon,
		MenuIcon,
	},
	props: {
		/**
		 * `value` determines the opening/closing state of the sidenav.
		 * initial - closed or open, depending on mobile or desktop view.
		 *   closed on mobile, same as 'main-open' on desktop.
		 */
		value: String, // 'initial' | 'main-open' | 'sub-open' | 'closed'
		priorities: {
			type: Array,
			default: null,
		},
		currentMetricKey: {
			type: String,
			required: true,
		},
		isEditing: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			// DOM element from props.mainContentId
			mainContentElem: null,
			editValue: "",
			currEditKey: "",
			activePriorityKey: "",
		};
	},
	computed: {
		activePriority() {
			if (!this.priorities) return null;
			return this.priorities.find((p) => p.key === this.activePriorityKey);
		},
		sortedMetrics() {
			const sorted = this.activePriority.metrics
				.slice()
				.sort((a, b) => a.name.localeCompare(b.name));
			return sorted;
		},
		stateClasses() {
			return {
				initial: this.value === "initial",
				"main-open": this.value === "main-open",
				"sub-open": this.value === "sub-open",
				closed: this.value === "closed",
			};
		},
		isOpen() {
			return ["main-open", "sub-open"].includes(this.value);
		},
	},
	methods: {
		selectPriority(p) {
			this.activePriorityKey = p.key;
			this.$emit("input", "sub-open");
		},
		selectMetric(m) {
			this.$emit("input", "closed");
			this.$emit("select-metric", m);
		},
		showEditInput(type, obj) {
			this.currEditKey = obj.key;
			this.editValue = obj.name;
			this.$nextTick(() => {
				if (type === "priority") {
					this.$refs.priorityInput[0].focus();
				} else {
					this.$refs.metricInput[0].focus();
				}
			});
		},
		saveEdit(type, obj) {
			this.$emit("edit", {
				type,
				key: obj.key,
				property: "name",
				value: this.editValue,
			});
			this.editValue = "";
			if (type === "priority") {
				this.$refs.priorityInput[0].blur();
			} else {
				this.$refs.metricInput[0].blur();
			}
		},
		onBack() {
			this.$emit("input", "main-open");
		},
		onSubNavMenuClick() {
			if (this.value === "closed") this.$emit("input", "sub-open");
			else this.$emit("input", "closed");
		},
		onMobileHamburgerClick() {
			if (!this.isOpen) this.$emit("input", "main-open");
			else this.$emit("input", "closed");
		},
	},
};
</script>

<style lang="scss" scoped>
@use "sass:color";
@import "@/styles/variables.scss";

$main-nav-width: 296px;
$sub-nav-width: 296px;

/* ---- SIDE NAV ---- */

#side-nav {
	flex-shrink: 0;
	color: white;
	position: relative;
	width: 100%;
	height: 60px;
	z-index: 10;
	overflow: hidden;
	transition: transform 0.5s ease;
	transform: translateX(0);

	.backdrop {
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	::-webkit-scrollbar-thumb {
		// I mean idk bout this.
		background-color: color.adjust($color-lightblue, $lightness: 20);
	}

	.mobile-hamburger {
		position: absolute;
		top: 12px;
		right: 12px;
		color: $color-blue;
		z-index: 2;
	}

	.back-btn {
		position: absolute;
		top: 12px;
		left: 12px;
		color: white;
		z-index: 3;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.5s ease;
		padding: 8px;
		.chev-left {
			margin-right: 8px;
			transform: translateY(1px);
		}
	}

	.top-container {
		height: 245px;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 18px;
		padding-bottom: 24px;
	}

	.pretitle {
		font-size: 14px;
		color: rgba(255, 255, 255, 0.6);
		margin-bottom: 8px;
		max-width: 205px;
	}

	.nav-title {
		font-weight: 600;
		font-size: 24px;
		letter-spacing: -0.03em;
	}

	.main-nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: $color-blue;
		display: flex;
		flex-direction: column;
		align-items: center;
		transform: translateX(-100vw);
		opacity: 0;
		transition: transform 0.5s ease, opacity 0.5s ease;
		z-index: 1;
		.lcap-logo {
			cursor: pointer;
			color: white;
			margin-bottom: auto;
		}
		.nav-item {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 23px 18px;
			cursor: pointer;
			transition: background 0.1s ease;
			.chevron-right {
				flex-shrink: 0;
			}
			&.active {
				background: $color-lightblue;
				box-shadow: 0px 8px 16px rgba(24, 70, 136, 0.8);
			}
			&:hover:not(.active) {
				background: color.adjust($color-lightblue, $alpha: -0.5);
			}
		}
		.logo-and-info {
			margin-top: auto;
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 20px;
			.info-btn {
				background-color: rgba(255, 255, 255, 0.16);
				font-weight: 600;
			}
		}
	}

	.sub-nav {
		position: absolute;
		display: flex;
		flex-direction: column;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: $color-darkblue;
		z-index: 2;
		transform: translateX(100vw);
		opacity: 0;
		transition: transform 0.5s ease, opacity 0.5s ease;
		.menu-icon-wrapper {
			position: absolute;
			top: 12px;
			right: 12px;
			cursor: pointer;
		}
		.top-container {
			align-items: flex-start;
		}
		.metrics {
			overflow: auto;
			flex: 1;
			.metric {
				// padding: 18px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 32px;
				cursor: pointer;
				transition: background 0.2s ease;
				&:hover:not(.active) {
					background: color.adjust($color-lightblue, $alpha: -0.5);
				}
			}
		}
	}

	@include md {
		position: fixed;
		top: 0;
		left: 0;
		background: $color-blue;
		width: $main-nav-width;
		height: 100vh;
		translate: translateX(0px);
		overflow: visible;
		.mobile-hamburger {
			display: none;
			color: white;
		}
		.main-nav {
			transform: translateX(0);
			opacity: 1;
			width: $main-nav-width;
		}
		.sub-nav {
			width: $sub-nav-width;
			position: absolute;
			transform: translateX(0);
			z-index: 0;
		}
	}

	&.main-open {
		position: fixed;
		height: 100vh;
		overflow: visible;
		.mobile-hamburger {
			color: white;
		}
		.main-nav {
			opacity: 1;
			transform: translateX(0);
		}
		.sub-nav {
			opacity: 1;
		}
	}

	&.sub-open {
		position: fixed;
		height: 100vh;
		overflow: visible;
		.back-btn {
			opacity: 1;
			pointer-events: auto;
		}
		.main-nav {
			opacity: 1;
			transform: translateX(0);
		}
		.sub-nav {
			opacity: 1;
			transform: translateX(0);
			display: flex;
			flex-direction: column;
			z-index: 2;
			.metrics {
				.metric {
					padding: 32px;
					&.active {
						background-color: $color-lightblue;
					}
				}
			}
		}

		@include md {
			.back-btn {
				display: none;
			}
			.sub-nav {
				z-index: 0;
				transform: translateX($main-nav-width);
				.top-container {
					align-items: flex-start;
				}
			}
		}
	}

	&.closed {
		@include md {
			transform: translateX(-$main-nav-width);
			.main-nav {
				transform: translateX(-$main-nav-width);
			}
			.sub-nav {
				display: flex;
				flex-direction: column;
				transform: translateX(60px);
				opacity: 1;
				.menu-icon-wrapper {
					top: 0;
					right: 0;
					padding-top: 12px;
					padding-right: 12px;
					width: 60px;
					height: 100%;
					position: absolute;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-end;
				}
				.metrics {
					overflow: hidden;
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}
}

/* ------ PADDING FOR MAIN CONTENT ------ */

.nav-closed {
	@include md {
		margin-left: 60px;
	}
}
.nav-main-open,
.nav-sub-open,
.nav-initial {
	@include md {
		margin-left: $main-nav-width;
	}
}

/* --------- ANIMATED MENU ICON --------- */

// https://codepen.io/designcouch/pen/Atyop

$nav-icon-spacing: 15px;

#nav-icon {
	width: 42px;
	height: 30px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
	color: inherit;
}

#nav-icon span {
	display: block;
	position: absolute;
	height: 5px;
	width: 100%;
	background: currentColor;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
	top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
	top: 15px;
}

#nav-icon span:nth-child(4) {
	top: 30px;
}

#nav-icon.open span:nth-child(1) {
	top: 15px;
	width: 0%;
	left: 50%;
}

#nav-icon.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
	top: 15px;
	width: 0%;
	left: 50%;
}
</style>
