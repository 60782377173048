import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// DEV CONFIG:
// const firebaseConfig = {
// 	apiKey: "AIzaSyDXlRXPcUl_T1sBlr23PBBXoyQi3i2ll_I",
// 	authDomain: "coeus-lcap-dev.firebaseapp.com",
// 	databaseURL: "https://coeus-lcap-dev-default-rtdb.firebaseio.com",
// 	projectId: "coeus-lcap-dev",
// 	storageBucket: "coeus-lcap-dev.appspot.com",
// 	messagingSenderId: "900436901966",
// 	appId: "1:900436901966:web:27eb85a999759ab91a831f",
// };

const firebaseConfig = {
	apiKey: "AIzaSyDTyUJRb6iWgqq8MdlGCViW3ymu9UXuneU",
	authDomain: "coeus-lcap.firebaseapp.com",
	databaseURL: "https://coeus-lcap.firebaseio.com",
	storageBucket: "coeus-lcap.appspot.com",
};

export { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
export { ref, set, push, remove, get, onValue } from "firebase/database";
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth(app);
