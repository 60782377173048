<template lang="pug">
LcapModal.reupload-modal(
	v-model="_value",
	hideFooter
)
	.reupload-modal-content
		h2 Reupload {{ metric && metric.name }}
		p.file-name(v-if="file") {{ file.name }}
		button.btn.btn-primary.relative
			| Choose a file to upload.
			input.file-input-hidden(
				type="file",
				placeholder="File",
				@change="onFileUpload"
			)
		button.confirm-btn.btn.btn-primary(v-if="data", @click="uploadData") Confirm Upload
</template>

<script>
import LcapModal from "@/components/LcapModal.vue";
import { getChartDataFromFile } from "@/parse";
import { reuploadMetricData } from "@/actions";

export default {
	name: "ReuploadDataModal",
	components: {
		LcapModal,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		metric: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			file: null,
			data: null,
			// Track state of in-flight request.
			uploading: false,
		};
	},
	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(v) {
				this.$emit("input", v);
			},
		},
	},
	watch: {
		value(v) {
			if (!v) {
				this.file = null;
				this.data = null;
				this.uploading = false;
			}
		}
	},
	methods: {
		async onFileUpload(ev) {
			this.file = ev.target.files[0];
			try {
				this.data = await getChartDataFromFile(this.file);
			} catch (err) {
				console.log("Error", err);
			}
		},
		async uploadData() {
			if (this.uploading) return;
			this.uploading = true;
			try {
				await reuploadMetricData(this.metric.data, this.data);
				this._value = false;
			} catch (err) {
				console.log("Error", err);
			} finally {
				this.uploading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.reupload-modal {
	.reupload-modal-content {
		padding: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
	}
}
</style>
