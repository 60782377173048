<template lang="pug">
.lcap-nav-container
	.logo-container(v-if="!hideLogo")
		img(src="../assets/img/lcap-logo-blue-png.png", alt="LCAP Logo")
	.buttons-container
		template(v-if="userData")
			button.btn(v-if="showHome", @click="$emit('home')")
				HomeIcon.btn-icon 
				| Home
			router-link(to="/home")
				button.btn
					| Admin Page
			button.btn.btn-outline(@click="signOut") Log Out
		button.btn.btn-transparent(v-else, @click="$emit('giveFeedback')") Give Feedback
</template>

<script>
import { auth, onAuthStateChanged, database, ref, get } from "@/firebase";
import HomeIcon from "@/components/icons/HomeIcon.vue";

export default {
	name: "LcapNav",
	components: {
		HomeIcon,
	},
	props: {
		hideLogo: {
			type: Boolean,
			default: false,
		},
		showHome: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showLogoutButtons: false,
			userData: null,
		};
	},
	created() {
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				this.showLogoutButtons = false;
				if (location.pathname !== "/login" && location.pathname !== "/") {
					window.location = "/login";
				}
				return;
			}
			get(ref(database, `users/${user.uid}`)).then(
				(snapshot) => {
					this.userData = snapshot.val();
				},
				(error) => {
					console.error(error);
				}
			);
		});
	},
	methods: {
		signOut() {
			auth.signOut();
			this.$router.push("login");
		},
	},
};
</script>

<style lang="scss" scoped>
.lcap-nav-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.logo-container {
		margin-right: auto;
	}

	.buttons-container {
		display: flex;
		*:not(:last-child) {
			margin-right: 16px;
		}
	}
}
</style>
