<template lang="pug">
transition(name="modal-fade")
	.lcap-modal(v-if="value", :class="{ open: value }")
		.backdrop(@click="onBackdropClick")
			button.backdrop-close-btn.close-btn(
				v-if="showCloseOnBackdrop",
				@click.stop="$emit('input', false)"
			) 
				CrossIcon
		.lcap-modal-content
			.lcap-modal-main.container
				button.close-btn(@click="$emit('input', false)")
					CrossIcon
				slot
			.lcap-modal-footer.container(v-if="!hideFooter")
				slot(name="footer")
</template>

<script>
import TransitionFade from "@/components/TransitionFade.vue";
import CrossIcon from "@/components/icons/CrossIcon.vue";

export default {
	name: "LcapModal",
	components: {
		TransitionFade,
		CrossIcon,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		showCloseOnBackdrop: {
			type: Boolean,
			default: false,
		},
		closeOnBackdropClick: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onBackdropClick() {
			if (this.closeOnBackdropClick) this.$emit("input", false);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
	.container {
		transform: translateY(25vh); //translateY(50vh);
	}
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.4s ease;
	.container {
		transition: transform 0.4s ease;
	}
}

.lcap-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 11;
	.backdrop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(24, 70, 136, 0.7);
		// Can't animate smoothly...
		// backdrop-filter: blur(20px);
		.backdrop-close-btn {
			color: white;
		}
	}
	.close-btn {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 24px;
		height: 24px;
		color: $color-danger;
	}
	.container {
		position: relative;
		background: #ffffff;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15),
			0px 0px 2px rgba(0, 0, 0, 0.15);
		border-radius: 12px;
		margin: 20px;
		// min-height: 100px;
	}
	.lcap-modal-content {
		display: flex;
		flex-direction: column;
		z-index: 2;
		width: calc(100vw - 40px);
		max-height: calc(100vh - 40px);
		* {
			min-height: 0;
		}
		.lcap-modal-main {
			overflow: auto;
			display: flex;
			flex-direction: column;
		}
		.lcap-modal-footer {
			flex-shrink: 0;
		}
	}
	@include md {
		.lcap-modal-content {
			width: unset;
			min-width: 600px;
			max-width: 900px;
		}
	}
}
</style>
