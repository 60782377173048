<template lang="pug">
Transition(name="fade-in")
	.give-feedback(v-if="value")
		button.close-btn(@click="close")
			CrossIcon
		.form-container(v-if="state === 'form'")
			img.lcap-logo(src="../assets/img/lcap-logo-blue-png.png", alt="LCAP Logo")
			h1.give-feedback-title LCAP Explorer Feedback
			p.description MUHSD values your feedback! Thank you for taking the time to provide us with your thoughts and recommendations for improving the LCAP Explorer.
			form.form(@submit.prevent="submitFeedback")
				.input-container
					input#feedback-email.input(
						v-model="email",
						type="text",
						placeholder="Email Address"
					)
					.err-msg-container
						p.err-msg(v-for="err in emailErrors") {{ err }}
				.input-container
					.text-area-container
						textarea#feedback-feedback.input.textarea(
							v-model="feedback",
							rows="12",
							placeholder="Add your feedback here..."
						)
						p.char-length(
							:class="{ error: feedbackErrors.length > 0, valid: validFeedback }"
						) {{ feedback.length }}/{{ FEEDBACK_MAX_LENGTH }}
					.err-msg-container
						p.err-msg(v-for="err in feedbackErrors") {{ err }}
					button.submit-btn.btn.btn-primary(@click.prevent="submitFeedback") Submit Feedback
			p.disclaimer Your email address will only be used to inform you of updates made regarding your feedback. We will not share your email with third-parties and will never send you spam.
		.success-container(v-else)
			p.text Thank you!
			p.text Your feedback has been submitted.
			button.exit-btn.btn.btn-primary(@click="close") EXIT
</template>

<script>
import CrossIcon from "@/components/icons/CrossIcon.vue";
import { submitFeedback } from "@/actions";
import { isEmail } from "@/util";

export default {
	name: "GiveFeedback",
	components: { CrossIcon },
	props: {
		// Visibility.
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			state: "form", // 'form' | 'success'
			email: "",
			emailErrors: [],
			feedback: "",
			feedbackErrors: [],
			submitting: false,
			error: false, // If there is an error on submit.

			FEEDBACK_MAX_LENGTH: 360,
		};
	},
	computed: {
		validFeedback() {
			return (
				this.feedback.length > 0 &&
				this.feedback.length <= this.FEEDBACK_MAX_LENGTH
			);
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(v) {
				if (!v) {
					// Reset state on close.
					this.email = "";
					this.feedback = "";
					this.emailErrors = [];
					this.feedbackErrors = [];
					this.state = "form";
					this.error = false;
				}
			},
		},
		feedback: {
			immediate: true,
			handler(v) {
				if (v.length > this.FEEDBACK_MAX_LENGTH) {
					this.feedbackErrors = ["You are exceeding the character limit"];
				} else {
					this.feedbackErrors = [];
				}
			},
		},
	},
	methods: {
		close() {
			this.$emit("input", false);
		},
		async submitFeedback() {
			if (this.submitting) return;
			this.emailErrors = [];
			this.feedbackErrors = [];
			const email = this.email.trim();
			const feedback = this.feedback.trim();

			if (!isEmail(email)) {
				this.emailErrors.push("Please enter a valid email");
			}
			if (feedback.length === 0) {
				this.feedbackErrors.push("Please enter some feedback");
			}
			if (feedback.length > this.FEEDBACK_MAX_LENGTH) {
				this.feedbackErrors.push("You are exceeding the character limit");
			}
			if (this.emailErrors.length > 0 || this.feedbackErrors.length > 0) {
				return;
			}

			try {
				this.submitting = true;
				await submitFeedback(email, feedback);
				this.state = "success";
			} catch (err) {
				this.error = true;
			} finally {
				this.submitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.fade-in-enter,
.fade-in-leave-to {
	opacity: 0;
}

.fade-in-enter-active,
.fade-in-leave-active {
	transition: opacity 0.3s ease;
}

.give-feedback {
	background-color: #e6eaec;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	z-index: 20;
	overflow: auto;
	padding: 20px;
	.close-btn {
		position: absolute;
		top: 24px;
		right: 24px;
		color: $color-danger;
	}
	.input {
		background: white;
		border-radius: 4px;
		font-weight: 500;
		color: $color-lightblue;
		border: none;
		outline: none;
		padding: 16px;
		font-size: 14px;
	}
	.textarea {
		resize: none;
		width: 100%;
	}
	.err-msg-container {
		padding: 2px 0;
		min-height: 32px;
		.err-msg {
			color: $color-danger;
			text-align: end;
			height: 24px;
		}
	}
	.text-area-container {
		position: relative;
		.char-length {
			position: absolute;
			bottom: 10px;
			right: 10px;
			&.error {
				color: $color-danger;
			}
			&.valid {
				color: $color-success;
			}
		}
	}

	.form-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		margin-top: 48px;
		max-width: 600px;
		.lcap-logo {
			max-width: 100%;
			height: auto;
			margin-bottom: 36px;
		}
		.give-feedback-title {
			margin-bottom: 16px;
			font-size: 24px;
			text-align: center;
		}
		.description {
			margin-bottom: 16px;
			text-align: center;
			line-height: 150%;
		}
		.form {
			width: 100%;
			margin-bottom: 24px;
			@include md {
				max-width: calc(100% - 96px);
			}
			.submit-btn {
				margin: 0 auto;
				margin-top: 24px;
			}
		}
		.disclaimer {
			margin-top: auto;
			text-align: center;
			font-size: 12px;
			line-height: 128%;
		}
	}

	.success-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.text {
			font-size: 24px;
			margin-bottom: 8px;
		}
		.exit-btn {
			margin-top: 48px;
			min-width: 160px;
		}
	}
}
</style>
