<template>
	<transition
		name="fade"
		mode="out-in"
		enter-active-class="fade-in"
		leave-active-class="fade-out"
		:appear="appear"
		@before-enter="setDuration"
		@before-leave="setDuration"
		@after-enter="afterEnter"
		@after-leave="afterLeave"
	>
		<slot></slot>
	</transition>
</template>

<script>
export default {
	name: "transition-fade",
	props: {
		appear: {
			type: Boolean,
			default: false,
		},
		duration: {
			type: Number,
			default: 500,
		},
		afterEnter: {
			type: Function,
			default() {
				return () => null;
			},
		},
		afterLeave: {
			type: Function,
			default() {
				return () => null;
			},
		},
	},

	computed: {
		transitionStyle() {
			return "transition-duration: 0.2s";
		},
	},

	methods: {
		setDuration(el) {
			// eslint-disable-next-line
			el.style.animationDuration = `${this.duration}ms`;
		},
	},
};
</script>

<style lang="scss">
.fade-in {
	animation-name: fadeIn;
}

.fade-out {
	animation-name: fadeOut;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
</style>
