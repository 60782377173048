<template lang="pug">
.chart-description
	.description-container(ref="descContainer")
		EditorContent.description(:editor="viewDescEditor")
		button.read-more.edit(v-if="isEditing", @click="editDescription") Edit Description
		button.read-more(v-else-if="showReadMore", @click="readDescription") Read More

	LcapModal.chart-desc-modal(
		v-model="showEditDescModal",
		:hide-footer="!isEditing",
		:close-on-backdrop-click="!isEditing"
	)
		template
			label.metric-name-label Metric Name
			input.metric-name-input.modal-input(
				v-if="isEditing",
				v-model="tempMetricName",
				:class="{ focused: metricNameFocused }",
				@focus="metricNameFocused = true",
				@blur="metricNameFocused = false"
			)
			p.metric-name(v-else) {{ metric.name }}
			label.metric-name-label.desc-label Metric Description
			EditorContent.modal-input.desc-input(
				:editor="isEditing ? editDescEditor : modalReadonlyEditor",
				:class="{ focused: editDescEditorFocused }"
			)
		template(#footer)
			.edit-btns(v-if="isEditing && editDescEditor")
				.modifiers
					button.edit-btn(
						:class="{ 'is-active': editDescEditor.isActive('bold') }",
						@click="editDescEditor.chain().focus().toggleBold().run()"
					)
						BoldIcon
					button.edit-btn(
						:class="{ 'is-active': editDescEditor.isActive('italic') }",
						@click="editDescEditor.chain().focus().toggleItalic().run()"
					)
						ItalicIcon
					button.edit-btn(
						:class="{ 'is-active': editDescEditor.isActive('underline') }",
						@click="editDescEditor.chain().focus().toggleUnderline().run()"
					)
						UnderlineIcon
					button.edit-btn(
						:class="{ 'is-active': editDescEditor.isActive('link') }",
						@click="setLink"
					)
						LinkIcon
				.actions
					.divider.first-divider
					button.edit-btn.cancel-btn(@click="showEditDescModal = false")
						| Cancel
					.divider
					button.edit-btn.save-btn(@click="saveChanges")
						| Save & Close
						CheckmarkIcon.checkmark
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";

import SideNav from "@/components/SideNav.vue";
import LcapNav from "@/components/LcapNav.vue";
import LcapModal from "@/components/LcapModal.vue";
import BoldIcon from "@/components/icons/BoldIcon.vue";
import UnderlineIcon from "@/components/icons/UnderlineIcon.vue";
import ItalicIcon from "@/components/icons/ItalicIcon.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import CheckmarkIcon from "@/components/icons/CheckmarkIcon.vue";
import { setLink } from "@/util";
import * as debounce from "debounce";

export default {
	name: "ChartDescription",
	components: {
		SideNav,
		LcapNav,
		LcapModal,
		EditorContent,
		BoldIcon,
		UnderlineIcon,
		ItalicIcon,
		LinkIcon,
		CheckmarkIcon,
	},
	props: {
		/**
		 * name: string;
		 * desc: Object; // The editor content.
		 */
		metric: {
			type: Object,
			required: true,
		},
		isEditing: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showReadMore: false,
			showReadMoreBtnCheck: null, // Debounced function to update showReadMore.
			showEditDescModal: false,
			descContainerObserver: null,

			// Variables for the tiptap editors. There are two: one on the page
			// under the title of a metric, and one for the modal. viewDesc is
			// readonly.
			viewDescEditor: null,
			modalReadonlyEditor: null,
			editDescEditor: null,
			editDescEditorFocused: false,
			metricNameFocused: false,
			tempMetricName: "",
			tempMetricDesc: null,
		};
	},
	computed: {
		// Required to use a single watcher handle for both metric and viewDescEditor.
		metricAndViewDescEditor() {
			return [this.metric, this.viewDescEditor];
		},
	},
	created() {
		this.viewDescEditor = new Editor({
			extensions: [StarterKit, Underline, Link],
			editable: false,
			onUpdate: () => {
				this.$nextTick(() => {
					this.readMoreBoundsCheck();
				});
			},
		});
		this.modalReadonlyEditor = new Editor({
			extensions: [StarterKit, Underline, Link],
			editable: false,
			onDestroy() {
				console.log("Ok...");
			},
		});
		this.editDescEditor = new Editor({
			extensions: [StarterKit, Underline, Link],
			editable: true,
			onFocus: () => (this.editDescEditorFocused = true),
			onBlur: () => (this.editDescEditorFocused = false),
		});
		this.showReadMoreBtnCheck = debounce(
			this.readMoreBoundsCheck.bind(this),
			100
		);
	},
	mounted() {
		/**
		 * This whole ResizeObserver implementation is required because as the sidenav
		 * closes, the descContainer changes size as the margins expand. This seems to
		 * be the cleanest way to react to any size changes in descContainer.
		 */
		this.descContainerObserver = new ResizeObserver(this.showReadMoreBtnCheck);
		this.descContainerObserver.observe(this.$refs.descContainer);
	},
	watch: {
		metricAndViewDescEditor: {
			immediate: true,
			handler() {
				if (!this.viewDescEditor) return;
				this.viewDescEditor.commands.setContent(this.metric.desc, true);
			},
		},
		showEditDescModal(v) {
			if (v) {
				this.tempMetricName = this.metric.name;
				this.tempMetricDesc = this.metric.desc;
				if (this.isEditing)
					this.editDescEditor.commands.setContent(this.tempMetricDesc);
				else this.modalReadonlyEditor.commands.setContent(this.metric.desc);
			}
		},
	},
	methods: {
		setLink() {
			setLink(this.editDescEditor);
		},
		/**
		 * Checks if the metric description on the page is overflowing, and if so,
		 * sets showReadMore to true, ergo adding the "read more" text at the bottom right.
		 * `entries` is just an implementation detail of ResizeObserver api.
		 */
		readMoreBoundsCheck(entries) {
			if (!entries) return;
			const div = entries[0].target;
			this.showReadMore = div.scrollHeight > div.clientHeight;
		},
		readDescription() {
			this.showEditDescModal = true;
		},
		editDescription() {
			this.showEditDescModal = true;
		},
		saveChanges() {
			this.$emit("updateMetric", {
				key: this.metric.key,
				name: this.tempMetricName,
				desc: this.editDescEditor.getHTML(),
			});
			this.showEditDescModal = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.description-container {
	position: relative;
	max-height: 60px;
	max-width: 480px;
	margin-bottom: 12px;
	overflow: hidden;
	.description {
		font-size: 14px;
		line-height: 20px;
	}
	.read-more {
		position: absolute;
		right: 0;
		bottom: 2px;
		cursor: pointer;
		color: $color-lightblue;
		font-size: 14px;
		font-weight: 600;
		padding-right: 42px;
		padding-left: 60px;
		background-image: linear-gradient(to right, transparent, $theme-light 33%);
		&.edit {
			text-decoration: underline;
		}
	}
}

.chart-desc-modal {
	::v-deep {
		.lcap-modal-main {
			padding: 36px;
		}
		.lcap-modal-footer {
			padding: 16px;
		}
	}
	.modal-input {
		color: rgba(79, 79, 79, 0.7);
		outline-offset: 4px;
		outline-color: $color-lightblue;
		margin-top: 8px;
		display: block;
		width: 100%;
	}
	.focused {
		color: $color-lightblue;
	}
	.metric-name-label {
		display: block;
		color: rgba(79, 79, 79, 0.7);
		text-transform: uppercase;
		font-weight: 600;
	}
	.metric-name-input,
	.metric-name {
		border: none;
		font-size: 24px;
		font-weight: 600;
		// letter-spacing: -0.05em;
	}
	.desc-label {
		margin-top: 16px;
	}
	.desc-input ::v-deep .ProseMirror {
		outline-offset: 4px;
		outline-color: $color-lightblue;
	}
	.edit-btns {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		gap: 12px;
		height: 100%;
		.edit-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 56px;
			height: 56px;
			border-radius: 16px;
			transition: all 0.1s ease;
			&.is-active {
				background: $color-lightblue;
				color: white;
				box-shadow: 0px 4px 16px rgba(24, 70, 136, 0.4);
			}
		}
		.modifiers {
			align-self: stretch;
			display: flex;
			justify-content: space-between;
		}
		.actions {
			border-top: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			justify-content: center;
			gap: 24px;
			padding-top: 20px;
		}
		.cancel-btn,
		.save-btn {
			width: unset;
			height: unset;
			text-transform: uppercase;
			font-weight: 600;
			color: rgba(79, 79, 79, 1);
			.checkmark {
				margin-left: 8px;
			}
		}
		.divider {
			border: 1px solid rgba(0, 0, 0, 0.1);
		}
		.first-divider {
			display: none;
		}
		@include sm {
			flex-direction: row;
			gap: 20px;
			.first-divider {
				display: block;
			}
			.modifiers {
				flex: 1;
				align-items: center;
			}
			.actions {
				padding-top: 0;
				border-top: 0;
			}
		}
	}
}
</style>
