import { database, ref, push, set, remove } from "@/firebase";

export function reuploadMetricData(chartKey, metricData) {
	return set(ref(database, `charts/pk/${chartKey}/data`), metricData);
}

export async function uploadMetric(
	// name, desc, children
	priority,
	// name, desc, data
	metric,
	// xAxis, yAxis, data
	metricData,
	makingNewPriority
) {
	const newChartRef = await uploadChartData(metricData);
	const newMetric = {
		...metric,
		data: newChartRef.key,
	};

	console.log("Chart data uploaded", newChartRef);

	const localPath = `districts/pk/local`;
	// First, we make a new chart entry containing the actual metric data.
	// If making a new priority, need to create:
	// 1. Make a new metric group with our new metric as the only entry, referencing
	//    the newly created data.
	// 2. Make a new priority, referencing the newly created metric group.
	if (makingNewPriority) {
		const newMetricGroupRef = await push(ref(database, `${localPath}/metrics`));
		console.log("Making new priority", newMetricGroupRef);
		await push(newMetricGroupRef, newMetric);
		await push(ref(database, `${localPath}/priorities`), {
			name: priority.name,
			children: newMetricGroupRef.key,
		});
	}
	// Otherwise, all we need to do is:
	// 1. Push the new metric under the existing metric group, referencing the newly
	//    created data.
	else {
		const r = await push(
			ref(database, `${localPath}/metrics/${priority.children}`),
			newMetric
		);
		console.log(
			"Existing priority, pushed!",
			`${localPath}/metrics/${priority.children}`,
			newMetric,
			r,
			priority
		);
	}
}

/**
 * `data` properties: { xAxis, yAxis, data }
 */
export function uploadChartData(data) {
	return push(ref(database, `charts/pk`), data);
}

/**
 *
 * @param {object} metric required properties: { groupKey, key, data }
 */
export async function deleteMetric(metric) {
	await remove(ref(database, `charts/pk/${metric.data}`));
	await remove(
		ref(database, `districts/pk/local/metrics/${metric.groupKey}/${metric.key}`)
	);
}

export async function submitFeedback(email, feedback) {
	return push(ref(database, `feedback/pk/new`), { email, feedback });
}
