import Papa from "papaparse";

export const schoolList = {
	0: "MUHSD District",
	1: "Merced High School",
	2: "Atwater High School",
	3: "Livingston High School",
	4: "Buhach Colony High School",
	5: "Yosemite High School",
	6: "Independence High School",
	10: "Golden Valley High School",
	12: "El Capitan High School",
	92: "Sequoia High School",
	all: "All Schools",
};

export async function getChartDataFromFile(file) {
	const results = await readCsvAsText(file);
	const data = await createChartDataModelFromCsvRows(results);
	return data;
}

export function readCsvAsText(file) {
	return new Promise((resolve, reject) => {
		let fileReader = new FileReader();
		fileReader.readAsText(file, "UTF-8");
		fileReader.onload = (e) => {
			let results = e.target.result;
			resolve(results);
		};
		fileReader.onerror = (e) => {
			reject(e);
		};
	});
}
/*
CSV Format: Category,colorValue,timeSeriesLabel,nVal,dVal,pnVal,pdVal,useRate,curRate,prevRate,hidden,schoolNumber
pnVal, dnVal not used.

see "createChartDataModelFromCsvRows" for info on each column.

Format of the data object:
Array<{
	code: number, // schoolNum, the number identifying a specific school.
	d: Array<{
		label: string, // The category (school, ethnicity, gender, w/e...)
		d: Array<{
			tf: number,
			color: any,
			rate?: number,
			num?: number,
			den?: number,
			hidden?: boolean
		}>
	}}
}>
Filter demographics is just an object with an array of categories.
*/
export function createChartDataModelFromCsvRows(fileContents) {
	let resolve, reject;
	const returnPromise = new Promise((_resolve, _reject) => {
		resolve = _resolve;
		reject = _reject;
	});
	Papa.parse(fileContents, {
		error(error) {
			// displayPopUp({
			// 	title: "oops",
			// 	message: "There was an error proccessing your csv",
			// });
			console.error(error);
			reject(error);
		},

		complete(results) {
			const d = [];

			// create maps to keep track of what we have created
			let schoolMap = {}; // schoolNum
			let categoryMaps = {};
			let timeFrameMaps = {};

			// rows to parse -- [ [], [] ] => {}
			let csvRows = results.data;

			for (let i in csvRows) {
				if (i == 0)
					// the first row is the labels
					continue;

				let thisRow = csvRows[i];

				// Category could be a school, ethnicity, gender, grade, etc...
				let category = thisRow[0];

				// Color is typically empty? Not entirely sure what it is yet.
				// Previous codebase has a comment stating that it's deprecated.
				let color = thisRow[1];

				// tf is the time series label. eg, the year (2017, 2019, w/e)
				// here tf stands for "time frame"
				let tf = thisRow[2];

				// num (nVal) is the numerator.
				// den (dVal) is the denominator.
				// eg, for number of students who passed summer school vs total number attended,
				// we could have 1711 for nVal and 1977 for dVal.
				let num = thisRow[3];
				let den = thisRow[4];

				// If a rate is manually supplied, (through useRate being truthy and curRate
				// having a value), then we can set a time series value "rate". Otherwise,
				// set the numerator and denominator.
				let useRate = thisRow[7];
				let curRate = thisRow[8];

				// Extra label to add to the time series object.
				let hidden = thisRow[10];

				// schoolNum maps to a school in the constant object schoolList as above.
				let schoolNum = thisRow[11];

				/* determine whether an object for this school code exists in d */

				// find index of school
				let indexOfSchool;
				if (Object.prototype.hasOwnProperty.call(schoolMap, schoolNum)) {
					// indexOfSchool is already recorded
					indexOfSchool = schoolMap[schoolNum];
				} else {
					// create school object
					let schoolObject = {
						code: schoolNum,
						// name : schoolNames[ schoolNum ],
						d: [],
					};

					// store index in map
					indexOfSchool = d.length;
					schoolMap[schoolNum] = indexOfSchool;

					// push to d
					d.push(schoolObject);
				}

				/* determine whether an object for this category exists in thisSchool */

				let thisSchool = d[indexOfSchool];

				// check if this school already has a category map, if not, create one.
				if (!Object.prototype.hasOwnProperty.call(categoryMaps, schoolNum)) {
					categoryMaps[schoolNum] = {};
				}

				let categoryMap = categoryMaps[schoolNum];

				// check if thisSchool already has a data entry for this category
				let indexOfCategory;

				if (Object.prototype.hasOwnProperty.call(categoryMap, category)) {
					// indexOfCategory has already been recorded
					indexOfCategory = categoryMap[category];
				} else {
					// create categoryObject
					let categoryObject = {
						label: category,
						d: [],
					};

					// store the index in map
					indexOfCategory = thisSchool.d.length;
					categoryMap[category] = indexOfCategory;

					// push it to d[ indexOfSchool ].d;
					thisSchool.d.push(categoryObject);
				}

				/* determine whether we have already recorded some data for this timeframe */

				let thisCategory = thisSchool.d[indexOfCategory];

				// check if we have a timeframe map for our school
				if (!Object.prototype.hasOwnProperty.call(timeFrameMaps, schoolNum)) {
					timeFrameMaps[schoolNum] = {};
				}

				let schoolTimeFrameMap = timeFrameMaps[schoolNum];

				// check if our school's time frame map has some data for our category
				if (
					!Object.prototype.hasOwnProperty.call(schoolTimeFrameMap, category)
				) {
					schoolTimeFrameMap[category] = {};
				}

				let timeFrameMap = schoolTimeFrameMap[category];

				// check if our school's category timeframe map has some data for our category
				if (Object.prototype.hasOwnProperty.call(timeFrameMap, tf)) {
					// this is an error. this data includes two contradicting entries
					console.warn(`Duplicate on line: ${i} - "${schoolNum}", "${category}", "${tf}"`);
					continue;
				} else {
					// create tf object
					let tfObject = {
						tf: tf,
						color: color,
					};

					// useRate is either "1" or empty.
					if (parseInt(useRate)) {
						tfObject.rate = curRate;
					} else {
						tfObject.num = num;
						tfObject.den = den;
					}

					if (hidden) {
						tfObject.hidden = true;
					}

					// add this to our data and record that we have seen it in our map
					thisCategory.d.push(tfObject);
					timeFrameMap[tf] = thisCategory.d.indexOf(tfObject);
				}
			}
			resolve(d);
		},
	});
	return returnPromise;
}
